import ReactDOM from "react-dom/client"
import App from "./App"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
// import reportWebVitals from "./reportWebVitals"

// Utils
import { client } from "./libs/apollo"

// Providers
import { ApolloProvider } from "@apollo/client"
import NotificationsProvider from "./context/notification/notification.context"

Sentry.init({
  dsn: "https://a0e00f03ed864f80922526dfe9a64bff@o4504895740641280.ingest.sentry.io/4504896026705920",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  // <React.StrictMode
  <ApolloProvider client={client}>
    <NotificationsProvider>
      <App />
    </NotificationsProvider>
  </ApolloProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
