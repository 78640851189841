export type ThemeType = typeof light // This is the type definition for my theme object.

export const light = {
	color: {
		primary: "#E42220",
		red: "#F04444",
		green: "#009432",
	},
	background: {
		primary: "#fff",
		secondary: "#F8F8F8",
		bodyBackgroundColor: "#F8F8F8",
		darkGray: "#E3E3E3",
	},
	fonts: {
		primary: "#000",
		secondary: "#fff",
		family: "'Sora', sans-serif",
	},
	button: {
		backgroundColor: "#E42220",
		secondaryBackgroundColor: "#f8f8f8",
		disabledBackgroundColor: "#E6E6E6",
		color: "#fff",
		secondaryColor: "#000",
		border: "1px solid #BBBBBB",
		// outlineColor: "#E42220",
		outlineColor: "#000",
	},
	breadcumb: {
		backgroundColor: "#E1E1E1",
		activeBackgroundColor: "#E42220",
		color: "#fff",
	},
	notification: {
		backgroundColor: "#fff",
		successColor: "#009432",
		errorColor: "#F04444",
		infoColor: "#000",
	},
	checkbox: {
		backgroundColor: "#F8F8F8",
		checkedBackgroundColor: "#E42220",
		color: "#000",
		checkedColor: "#fff",
	},
	steps: {
		disabledButtonColor: "#EBEBEB",
		buttonColor: "#E42220",
		activeStepColor: "#E42220",
		inactiveStepColor: "#EBEBEB",
		prevStepColor: "#FFA09F",
		borderColor: "#E1E1E1",
	},
	input: {
		labelColor: "#000",
		borderColor: "#BBBBBB",
		backgroundColor: "#fff",
	},
	plumbingInput: {
		containerBackgroundColor: "#F3F3F3",
		inputBackgroundColor: "#fff",
		inputColor: "#000",
		inputBorderColor: "#BBBBBB",
		labelColor: "#9A9A9A",

		iconBackgrouncColor: "#CFCFCF",
		activeIconBackgrouncColor: "#E42220",
	},
	numberInput: {
		containerBackgroundColor: "#F3F3F3",

		iconBackgrouncColor: "#CFCFCF",
		activeIconBackgrouncColor: "#E42220",
		iconBorderColor: "#CFCFCF",
		activeIconBorderColor: "#E42220",

		buttonBackgroundColor: "#CFCFCF",
		buttonColor: "#fff",

		inputBorderColor: "#BBBBBB",
		inputBackgroundColor: "#fff",
		inputColor: "#9A9A9A",
	},
	floorInput: {
		containerBackgroundColor: "#EEEEEE",
		activeContainerBackgroundColor: "#E42220",
	},
	roomInput: {
		borderColor: "#000",
	},
	questionRadiobox: {
		backgroundColor: "#F8F8F8",
		checkedBackgroundColor: "#E42220",
		color: "#000",
		checkedColor: "#fff",
		iconBackgroundColor: "#D8D8D8",
		iconColor: "#000",
		hoverIconBackgroundColor: "#E42220",
		hoverIconColor: "#fff",
	},
	infoModal: {
		backgroundColor: "#fff",
		leftBorder: ".3rem solid #E42220",
	},
	electricalInstallationsRoomsOverview: {
		roomsContainerBackgroundColor: "#F3F3F3",
		roomBackgroundColor: "#DEDEDE",
		roomColor: "#000",
		activeRoomBackgroundColor: "#E42220",
		activeRoomColor: "#fff",
		titleColor: "#272727",
	},
	summary: {
		item: {
			valueBackgroundColor: "#ECECEC",
		},
		boilerTable: {
			oddBackgroundColor: "#EBEBEB",
			evenBackgroundColor: "#F2F2F2",
			border: "1px solid #C7C7C7",
		},
		plumbingTable: {
			oddBackgroundColor: "#EBEBEB",
			evenBackgroundColor: "#F2F2F2",
			border: "1px solid #C7C7C7",
		},
	},
	breakpoints: {
		sm: "640px",
		md: "768px",
		lg: "1024px",
		xl: "1280px",
		xxl: "1536px",
	},
} as const

// export const dark: ThemeType = {
// 	colors: {

// 	},
// 	breakpoints: {
// 		sm: "640px",
// 		md: "768px",
// 		lg: "1024px",
// 		xl: "1280px",
// 		xxl: "1536px",
// 	},
// } as const

const theme = light // set the light theme as the default.
export default theme
